import React from "react";
import Banner from "../../../components/Landers/Banner";
import Brands from "../../../components/Landers/Brands";
import Footer from "../../../components/Landers/Footer";
import GetStarted from "../../../components/Landers/GetStarted";
import Header from "../../../components/Landers/Header";
import Info from "../../../components/Landers/Info";
import styles from "../../../components/Landers/landers.module.scss";
import SectionTemplate from "../../../components/Landers/SectionTemplate";
import SEO from "../../../components/Landers/SEO";
import Testimonials from "../../../components/Landers/Testimonials";
import ThreeSteps from "../../../components/Landers/ThreeSteps";
import WhatsUnique from "../../../components/Landers/WhatsUnique";

const steps = [
  {
    h5: "Request Employment Verification",
    p:
      "Initiate the background check process for a candidate for whom you need to run employment screening.",
  },
  {
    h5: "Run Employment Checks",
    p: `SpringVerify uses Knowledge-Based Authentication (KBA) to verify the candidate’s history of employment,
      designation, duration, and more. It also checks if they are registered with statutory bodies like MCA/ROC, TAN, PF, etc.`,
  },
  {
    h5: "Get real-time updates",
    p:
      "Get notified about your candidate’s verification status with real time updates. Make informed decisions by hiring the right person for the role.",
  },
];

const testimonials = [
  {
    name: "Rupasi M",
    company: "Datarynx Pvt. Ltd.",
    p: `SpringVerify is our go-to app for all our background checks.
			I'm in love with its flexibility, time-bound closures,
			and a support team that is always ready to answer our queries 24x7.`,
  },
  {
    name: "Ravindra M",
    company: "Rupeezen",
    p: `I love the simplicity and clean UI of SpringVerify. As the checks are digitized, there's no need for human intervention.
			This has helped us to quicken the onboarding time for our fresh hires.`,
  },
  {
    name: "Maryam J",
    company: "Resolvit Management and Technology Consulting India Pvt. Ltd.",
    p: `SpringVerify is very simple, reliable, and straightforward. The UX is awesome, checks are fast and most importantly all
			my queries are answered promptly without me having to wait for long.`,
  },
];

export default () => (
  <div className={styles.solutions}>
    <SEO
      title="Employment Verification Service | SpringVerify India"
      desc="SpringVerify screens the candidate's employment history, designations and titles in an instant seamlessly for a streamlined hiring process."
      url="https://in.springverify.com/screenings/employment-verification/"
      imgurl="https://assets-sv-in.gumlet.io/test-pages/SV-IN+(Employment+verification).jpg"
    />
    <div className={styles.topSection}>
      <Header />
      <Banner
        h1="Make Informed Decisions with Employment Verifications"
        p="Hire with confidence by verifying your candidate’s employment history, title, duration, and more with SpringVerify."
        img="https://assets-sv-in.gumlet.io/LP/Employment/banner.png"
      />
    </div>
    <Brands />
    <section>
      <SectionTemplate
        img="Employment/need.svg"
        h2="Need for Employment Verifications"
        p={`Providing false information about their previous employment experiences is becoming a common practice among the applicants.
				As a result, it is important to have an effective screening process in place. Here’s how employment verifications can help
				your company to:`}
        ul={[
          "Mitigate the risk of hiring a candidate with false credentials",
          "Ensure the candidate’s authenticity with regards to their employment history",
          "Identify false employment claims, gaps in employment, or fabrication of job titles",
        ]}
      />
    </section>
    <Info
      h2="Information you will get\nfrom Employment Verifications"
			path="Employment"
      info={[
        "Complete employment history",
        "Job designations held",
        "Duration of employment",
        "Professional conduct",
      ]}
    />
    <ThreeSteps
      steps={steps}
      candidate={"https://assets-sv-in.gumlet.io/LP/Employment/candidate.svg"}
      path="Employment"
    />
    <WhatsUnique
      path="Employment"
      info={[
        "Easy onboarding",
        "Safe and secure",
        "Transparent pricing",
        "Workflow automation",
        "Remote-friendly and contactless verifications",
        "Compliant with ISO 27001, PCI, and more",
        "Tailored solutions based on your requirements",
        "Powered by AI and Blockchain",
      ]}
    />
    <Testimonials testimonials={testimonials} path="Employment" />
    <GetStarted
      img="Employment/get-started.svg"
      h3="Ready to get started\nwith us?"
    />
    <Footer />
  </div>
);
